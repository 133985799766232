.sports-theme {
  --ion-color-primary: #4f2683;
  --ion-color-primary-rgb: 79, 38, 131;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #462173;
  --ion-color-primary-tint: #613c8f;

  --ion-color-secondary: #ffc62f;
  --ion-color-secondary-rgb: 255, 198, 47;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0ae29;
  --ion-color-secondary-tint: #ffcc44;

  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 0, 0, 0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  --ion-color-success: #cc006d;
  --ion-color-success-rgb: 204, 0, 109;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #b40060;
  --ion-color-success-tint: #d11a7c;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #bc4ed8;
  --ion-color-danger-rgb: 188, 78, 216;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a545be;
  --ion-color-danger-tint: #c360dc;

  --ion-color-dark: #ffd200;
  --ion-color-dark-rgb: 255, 210, 0;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #e0b900;
  --ion-color-dark-tint: #ffd71a;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  .overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--custom-primary-transp); //rgba(128, 128, 128, 0.5)
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ion-color-favorite {
    --ion-color-base: var(--ion-color-favorite);
    --ion-color-base-rgb: var(--ion-color-favorite-rgb);
    --ion-color-contrast: var(--ion-color-favorite-contrast);
    --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
    --ion-color-shade: var(--ion-color-favorite-shade);
    --ion-color-tint: var(--ion-color-favorite-tint);
  }

  --ion-default-font: "Arimo", sans-serif !important;
  --text-font-size: 14px;

  --custom-primary: #4f2683;
  --custom-primary-transp: rgb(79, 38, 131, 0.5);
  --custom-accent: #ffc62f;
  --my-custom-primary: #4f2683;

  --custom-back: #4f2683;
  --my-custom-background: linear-gradient(
    -30deg,
    var(--custom-primary) 0%,
    var(--custom-back) 100%
  );

  --custom-card: #d7d8da;
  //--my-custom-background-card: linear-gradient(to right, var(--custom-back) 0%, var(--custom-card) 100%);
  --my-custom-background-card: var(--ion-color-primary);

  //--my-custom-color: var(--my-custom-primary);
  --my-custom-color2: var(--custom-accent);
  --my-custom-color3: #948d8d4d;
  --my-custom-color-h: #e9e5e5;
  --my-custom-color4: rgb(255, 255, 255);
  --my-custom-active: #0ad8f0;
  --my-custom-color5: #f5f5f9;

  --custom-background-cover: #868381a6;
  --custom-images-primary: #d5d2d200;
  --custom-images-secondary: var(--my-custom-active);
  --custom-image-color: linear-gradient(
    to bottom,
    var(--custom-images-primary) 0%,
    var(--custom-images-secondary) 100%
  );

  ion-content {
   // --background: var(--my-custom-background);
   // --background: #EBEFF3;
   --background: #ffffff;
  }

  .md,
  .ios {
    ion-toast {
      --background: var(--my-custom-color2);
      color: var(--ion-color-primary);
    }

    /* Use the primary color as the background for the toolbar */
    --ion-toolbar-background: var(--my-custom-primary);

    /* Change the color of the toolbar components */
    --ion-toolbar-color: var(--ion-color-primary-contrast);

    /* Change the color of the activated toolbar components */
    --ion-toolbar-color-activated: #fff;

    /* Change the color of the unchecked segment buttons */
    --ion-toolbar-color-unchecked: rgba(255, 255, 255, 0.6);

    /* Change the color of the checked segment button */
    --ion-toolbar-color-checked: #fff;
  }

  .md {
    ion-searchbar {
      padding: 0px;
    }

    // ion-card {
    //   background: var(--my-custom-background-card);
    // }
  }

  .ios {
    ion-searchbar {
      max-height: 44px !important;
      padding: 0px;
    }

    ion-item {
      --inner-padding-end: 0 !important;
    }

    // ion-card {
    //   background: var(--my-custom-background-card);
    // }
  }
}
