.alpha-theme{
    --ion-color-primary: #0075C9;
    --ion-color-primary-rgb: 79, 38, 131;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0075C9;
    --ion-color-primary-tint: #0075C9;

    --ion-color-secondary: #70c4ff;
    --ion-color-secondary-rgb: 255, 198, 47;
    --ion-color-secondary-contrast: #fff;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #fff;
    --ion-color-secondary-tint: #fff;

    --ion-color-tertiary: #000000;
    --ion-color-tertiary-rgb: 0, 0, 0;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #000000;
    --ion-color-tertiary-tint: #1a1a1a;

    --ion-color-success: #cc006d;
    --ion-color-success-rgb: 204, 0, 109;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #b40060;
    --ion-color-success-tint: #d11a7c;

    --ion-color-warning: #0075C9;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #0075C9;
    --ion-color-warning-tint: #0075C9;

    --ion-color-danger: #0075C9;
    --ion-color-danger-rgb: 188, 78, 216;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #0075C9;
    --ion-color-danger-tint: #0075C9;

    --ion-color-dark: #101820;
    --ion-color-dark-rgb: 255, 210, 0;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #101820;
    --ion-color-dark-tint: #101820;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;



    .overlay{
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: var(--custom-primary-transp); //rgba(128, 128, 128, 0.5)
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ion-color-favorite {
      --ion-color-base: var(--ion-color-favorite);
      --ion-color-base-rgb: var(--ion-color-favorite-rgb);
      --ion-color-contrast: var(--ion-color-favorite-contrast);
      --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
      --ion-color-shade: var(--ion-color-favorite-shade);
      --ion-color-tint: var(--ion-color-favorite-tint);
    }

    --ion-default-font: 'Arimo', sans-serif !important;
    --text-font-size: 14px;

    --custom-primary: #243949;
    --custom-primary-transp: rgb(36, 57, 73, 0.5);
    --custom-accent: #517fa4;
    --my-custom-primary: linear-gradient(to right, #0075C9 0%, #0075C9 100%);

    --custom-back: #0075C9;
    --my-custom-background: linear-gradient(-50deg, var(--custom-primary) 0%, var(--custom-back) 100%);
    //--my-custom-background: #0075C9;

    --custom-card: #0075C9; //aqui blanco
    --my-custom-background-card: #176eac;

    //--my-custom-color: var(--my-custom-primary);
    --my-custom-color2: var(--custom-accent);
    --my-custom-color3: #948d8d4d;
    --my-custom-color-h: #e9e5e5;
    --my-custom-color4: rgb(255, 255, 255);
    --my-custom-active: #0AD8F0;
    --my-custom-color5: #f5f5f9;
    --button-login: #fff;

    --custom-background-cover: #868381a6;
    --custom-images-primary: #d5d2d200;
    --custom-images-secondary: var(--my-custom-active);
    --custom-image-color: linear-gradient(to bottom, var(--custom-images-primary) 0%, var(--custom-images-secondary) 100%);

    ion-content {
     --background: var(--my-custom-background);
    // --background: #EBEFF3;
    // --background: #fff;
    }

   .md,
    .ios {
      ion-toast {
        --background: var(--ion-color-secondary);
        color: var(--ion-color-primary);
      }

      // Use the primary color as the background for the toolbar
      --ion-toolbar-background: var(--my-custom-primary);

      // Change the color of the toolbar components
      // --ion-toolbar-color: var(--ion-color-primary-contrast);

      // Change the color of the activated toolbar components
      --ion-toolbar-color-activated: #fff;

      // Change the color of the unchecked segment buttons
      --ion-toolbar-color-unchecked: rgba(255, 255, 255, 0.6);

      // Change the color of the checked segment button
      --ion-toolbar-color-checked: #fff;
    }

    .md {
      ion-searchbar {
        padding: 0px;
      }

      // ion-card {
      //   background: var(--my-custom-background-card);
      // }
    }

    .ios {
      ion-searchbar {
        max-height: 44px !important;
        padding: 0px;
      }

      ion-item {
        --inner-padding-end: 0 !important;
      }

      // ion-card {
      //   background: var(--my-custom-background-card);
      // }
    }

    /*Estilos Dietas*/
      h3.h3_alpha{
        color:#0075C9;
        font-weight: 700;
    }

    .icon_alpha {
        font-size: 150px;
        color: #0075C9;
        margin-bottom: 30px;
    }


    .cont-formulario{ margin: 10px; }

    .cont-formulario .h3_alpha{
        text-align: center;
        margin: 25px 0 0px 0;
    }

    .cont-formulario ul{
        list-style-type: none;
        padding: 10px;
    }

    .alpha span{
        color:#0075C9;
        font-weight: 600;
    }

  /*Estilos acordeon Dietas Alpha*/
  ion-segment {
    border-radius: 15px;
  }

  .alpha-dias{
    color:#0075C9;
  }
  .segment-button-checked{
    color: #0075c9;
  }

  /*Estilos dieta datos Alpha*/

  .alpha-semana span{
    color:#0075C9;
    font-weight: 600;
  }

  .alpha-semana {
    margin: 0 0 0 -15px;
  }

  .alpha-semana li{
    margin: 0 0 15px 0;
  }

  /*Estilos tabla de alimentos*/
  .column-alpha {
    background-color: white;
    border: solid 1px #0075C9;
    color: #0075C9;
    font-weight: 600;
  }

  .title-modal{
    color: #fff;
  }

  .cerrar-modal{
    color: #fff;
  }

  /*Estilos tabla Alpha alimentos*/
  .titulo-tabla-alpha{
    background: #0075C6;
    color: white;
    text-align: center;
    font-weight: 500;
  }


  .tabla-alpha {
    text-align: center;
    border-style: solid;
    border-color: #0075C9;
    border-width: 1px;
    font-weight: 600;
    background-color: #fff;
    color: #0075C9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /*Estilos alerta ERROR*/
  ion-alert .alert-wrapper .alert-button-group {
    background: #0075c9!important;
    color: white;
    button {
      color: white;
      padding: 10px 33px;
      font-weight: 400;
    }
  }

  .alert-button-group.sc-ion-alert-md {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .alert-title.sc-ion-alert-md {
    color: #0075c9;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }

  .alert-sub-title.sc-ion-alert-md {
    color: var(--ion-text-color, #000);
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .alert-wrapper.sc-ion-alert-md {
    border-radius: 10px!important;
  }
}
