@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "global-components";
@import "global-custom";
@import '~animate.css/animate.min.css';

.cart-modal {
  --height: 50%;
  --border-radius: 10px;
  padding: 25px;
  .list .item {
    background-color: #bbbd46 !important;
    border-width: 1px !important;
    border-style: ridge !important;
    border-color: #C0C0C0 !important;
  }

  .list {
    margin-top:0% !important;
  }
}


