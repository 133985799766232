.cimera-theme {
  --ion-color-primary: #1F1F1F;
  --ion-color-primary-rgb: 79, 38, 131;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1F1F1F;
  --ion-color-primary-tint: #1F1F1F;

  --ion-color-secondary: #9c8311;
  --ion-color-secondary-rgb: 255, 198, 47;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #9c8311;
  --ion-color-secondary-tint: #9c8311;

  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 0, 0, 0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  --ion-color-success: #cc006d;
  --ion-color-success-rgb: 204, 0, 109;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #b40060;
  --ion-color-success-tint: #d11a7c;

  --ion-color-warning: #9c8311;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #9c8311;
  --ion-color-warning-tint: #9c8311;

  --ion-color-danger: #9c8311;
  --ion-color-danger-rgb: 188, 78, 216;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #9c8311;
  --ion-color-danger-tint: #9c8311;

  --ion-color-dark: #1F1F1F;
  --ion-color-dark-rgb: 255, 210, 0;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #1F1F1F;
  --ion-color-dark-tint: #1F1F1F;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  .overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--custom-primary-transp); //rgba(128, 128, 128, 0.5)
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ion-color-favorite {
    --ion-color-base: var(--ion-color-favorite);
    --ion-color-base-rgb: var(--ion-color-favorite-rgb);
    --ion-color-contrast: var(--ion-color-favorite-contrast);
    --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
    --ion-color-shade: var(--ion-color-favorite-shade);
    --ion-color-tint: var(--ion-color-favorite-tint);
  }

  --ion-default-font: "Arimo", sans-serif !important;
  --text-font-size: 14px;

  --custom-primary: #1F1F1F;
  --custom-primary-transp: var(--ion-color-secondary);
  --custom-accent: #1F1F1F;
  --my-custom-primary: #1F1F1F;

  --custom-back: #1F1F1F;
  --my-custom-background: linear-gradient(-50deg,var(--custom-primary) 0%,var(--custom-back) 100%);

  --custom-card: #d7d8da;
  //--my-custom-background-card: linear-gradient(to right, var(--custom-back) 0%, var(--custom-card) 100%);
  --my-custom-background-card: var(--ion-color-primary);

  //--my-custom-color: var(--my-custom-primary);
  --my-custom-color2: var(--custom-accent);
  --my-custom-color3: #948d8d4d;
  --my-custom-color-h: #e9e5e5;
  --my-custom-color4: rgb(255, 255, 255);
  --my-custom-active: #0ad8f0;
  --my-custom-color5: #f5f5f9;

  --custom-background-cover: #868381a6;
  --custom-images-primary: #d5d2d200;
  --custom-images-secondary: var(--my-custom-active);
  --custom-image-color: linear-gradient(to bottom,var(--custom-images-primary) 0%,var(--custom-images-secondary) 100%);

  ion-content {
   // --background: var(--my-custom-background);
   //   --background: #EBEFF3;
    --background: #ffffff;
  }

  .md,
  .ios {
    ion-toast {
      --background: var(--ion-color-secondary);
      color: var(--ion-color-primary);
    }

    /* Use the primary color as the background for the toolbar */
    --ion-toolbar-background: var(--my-custom-primary);

    /* Change the color of the toolbar components */
    // --ion-toolbar-color: var(--ion-color-primary-contrast);

    /* Change the color of the activated toolbar components */
    --ion-toolbar-color-activated: #fff;

    /* Change the color of the unchecked segment buttons */
    --ion-toolbar-color-unchecked: rgba(255, 255, 255, 0.6);

    /* Change the color of the checked segment button */
    --ion-toolbar-color-checked: #fff;
  }

  .md {
    ion-searchbar {
      padding: 0px;
    }

    // ion-card {
    //   background: var(--my-custom-background-card);
    // }
  }

  .ios {
    ion-searchbar {
      max-height: 44px !important;
      padding: 0px;
    }

    ion-item {
      --inner-padding-end: 0 !important;
    }

    // ion-card {
    //   background: var(--my-custom-background-card);
    // }
  }

/*Estilos Dietas*/

  h3.h3_cimera{
    color: darkgoldenrod!important;;
    font-weight: 700;
}

.icon_cimera {
    font-size: 150px;
    color: darkgoldenrod!important;
    margin-bottom: 30px;
}

.cont-formulario{
    margin: 10px;
}

.cont-formulario .h3_cimera{
    text-align: center;
    margin: 25px 0 0px 0;
}

.cont-formulario ul{
    list-style-type: none;
    padding: 10px;
}

.cimera span{
    color:darkgoldenrod;
    font-weight: 600;
}

/*Estilos acordeon Dietas*/
.cimera-dias{
  color:darkgoldenrod;
}
.segment-button-checked{
  color: darkgoldenrod;
}

/*Estilos dieta datos Cimera*/

.cimera-semana span{
  color:darkgoldenrod;
  font-weight: 600;
}

.cimera-semana {
  margin: 0 0 0 -15px;
}

.cimera-semana li{
  margin: 0 0 15px 0;
}

/*Estilos tabla de alimentos*/
.column-cimera {
  background-color: white;
  border: solid 1px darkgoldenrod;
  color: #000;
  font-weight: 600;
}

.title-modal{
  color: #fff;
}

/*Estilos tabla Cimera alimentos*/
.titulo-tabla-cimera {
  background: darkgoldenrod;
  color: white;
  text-align: center;
  font-weight: 500;
}


.tabla-cimera {
  text-align: center;
  border-style: solid;
  border-color: #000;
  border-width: 1px;
  font-weight: 600;
  background-color: #fff;
  color: darkgoldenrod;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Estilos alerta ERROR*/
ion-alert .alert-wrapper .alert-button-group {
  background: darkgoldenrod!important;
  color: white;
  button {
    color: white;
    padding: 10px 33px;
    font-weight: 400;
  }
}

.alert-button-group.sc-ion-alert-md {
  display: flex;
  width: 100%;
  align-items: center;
}

.alert-title.sc-ion-alert-md  {
  color:darkgoldenrod;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.alert-sub-title.sc-ion-alert-md {
  color: var(--ion-text-color, #000);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.alert-wrapper.sc-ion-alert-md {
  border-radius: 10px!important;
}

.franja-reserva{
  display: flex;
  justify-content: center;
  padding: 15px 0px 5px 0px;
  margin: 0px 0px 15px 0px;
}

.franja-reserva h5 {
  font-weight: 600;
}



}



